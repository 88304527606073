import Vue from "vue";
import axios from "axios";
import {
  message
} from "ant-design-vue";
import {
  getCookie
} from '@/plugins/utils'

const request = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  baseURL: '/api',
  timeout: 15000,
})

const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    message.error(`${status || ''}  ${data.msg || 'error'}`)
  } else {
    message.error(error.message || '请求出错，请稍后重试！')
  }
  return Promise.reject(error)
};

request.interceptors.request.use((config) => {
  const token = getCookie('service_token')
  // 如果 token 存在
  if (token) {
    config.headers.Accept = `application/json`
    config.headers.Authorization = token
  }

  return config
}, errorHandler)

request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)
// 使用拦截器，定义全局请求头
// request.interceptors.request.use(config => {
//   // 在请求头中添加token
//   config.headers.Authorization = storage.get('authorization')
//   return config
// })
Vue.prototype.$http = request;

export default request;