<template>
  <div>
    <van-tabbar
    class="footer"
      v-model="active"
      active-color="#3a76c8"
      inactive-color="#000"
      :fixed="true"
    >
      <van-tabbar-item replace icon="wap-home-o" to="/">
        <span>首页</span>
      </van-tabbar-item>
      <van-tabbar-item replace icon="bag-o" to="/market/index"
        >市场</van-tabbar-item
      >
      <van-tabbar-item replace icon="chat-o" to="/consult/index"
        >咨询</van-tabbar-item
      >
      <van-tabbar-item replace icon="contact" to="/my/index"
        >我的</van-tabbar-item
      >
      <van-tabbar-item
        replace
        icon="setting-o"
        to="/qyIndex/index"
        v-show="$store.state.admin == 1"
        >管理</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: Number(sessionStorage.getItem("active")) || 0,
      // admin:sessionStorage.getItem('isAdmin')==null?this.$store.state.admin:sessionStorage.getItem('isAdmin')
    };
  },
  created(){

  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
  /deep/ .van-tabbar--fixed{
    left: unset !important;
  }
</style>
