import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as echarts from "echarts";

import "./plugins/axios";
import "./plugins/vant";
import "./plugins/antd";
import "./plugins/utils";
import "./plugins/amfe";
import "amfe-flexible";

if(sessionStorage.getItem('isAdmin')==null){
  console.log('sessionStorage.getItem',sessionStorage.getItem('isAdmin'))
setTimeout(() => {
    store.dispatch('JudgeAdmin')    
}, 800);
}
else{
  store.state.admin=sessionStorage.getItem('isAdmin')
}
import Multiselect from 'vue-multiselect'
// register globally
Vue.component('multiselect', Multiselect)
import Footer from '@/components/footer'
import addTime from '@/components/datetimePicker'
import ComaddTime from '@/components/ComdatetimePicker'
import chanceStyle from '@/components/chanceList'
import comChanceDetail from '@/components/comChanceDetail'
import VueWechatTitle from 'vue-wechat-title' //动态修改title
Vue.use(VueWechatTitle)
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.component('Footer', Footer)
Vue.component('addTime', addTime)
Vue.component('ComaddTime', ComaddTime)
Vue.component('comChanceDetail', comChanceDetail)
Vue.component('chanceStyle', chanceStyle)
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");