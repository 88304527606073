<template>
  <div class="component">
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        @cancel="show = false"
        @confirm="choseDate"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      currentDate: new Date(),
      index: "",
      begin: "",
    };
  },
  watch: {},
  methods: {
    $show(i, e) {
      this.show = true;
      this.index = i;
      this.name = e;
    },
    choseDate(e) {
      var dayjs = require("dayjs");
      console.log(e);
      let dateTime = dayjs(e).format();
      let time = dateTime.split("T").join(" ").slice(0, 19);
      console.log(time);
      this.show = false;
      this.$emit("change", time);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-action-sheet__header {
  font-weight: bolder;
}
</style>
