import request from "@/utils/request";
/**
 * 登录
 */
export class loginApi {
    static login(params) {
        return request({
            url: "/auth/login",
            method: "get",
            params: params,
        });
    }
}