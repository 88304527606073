import {
  Toast
} from 'vant'
import axios from 'axios'
import {
  getCookie
} from '@/utils'
import storage from 'store'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  baseURL: '/api',
  timeout: 1200000, // 请求超时时间
})
const errorMessage = (message) => {
  Toast({
    position: 'top',
    message
  })
}
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    if (status === 401) {
      errorMessage(data.msg)
    } else {
      errorMessage(`${status || ''}  ${data.msg || 'error'}`)
    }
  } else {
    errorMessage(error.message || '请求出错，请稍后重试！')
  }
  return Promise.reject(error)
}
// 使用拦截器，定义全局请求头
// request.interceptors.request.use(config => {
//   // 在请求头中添加token
//   config.headers.Authorization = storage.get('authorization')
//   return config
// })

// request interceptor
request.interceptors.request.use(config => {
  const token = getCookie('service_token')
  if (token) {
    config.headers.Accept = `application/json`
    config.headers.Authorization = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request