import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import getters from './getters'
import {
  qyIndexApi
} from "@/api/qyIndex";
import {
  getCookie
} from "@/plugins/utils";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin: ""
  },

  actions: {
    JudgeAdmin(context, value) {
      const token = getCookie("authorization");
      qyIndexApi.isAdmin({
        authorization: token
      }).then((res) => {
        if(res.code==0){
          console.log(res);
        value = res.data;
        console.log('admin', value)
        context.commit('Judge', value)
        sessionStorage.setItem('isAdmin',value)
        }
      });
    },
  },
  mutations: {
    Judge(state, value) {
      state.admin = value;
    },
  },
  modules: {
    app
  },
  getters
})