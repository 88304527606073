import request from "@/utils/request";
/**
 * 首页
 */
export class qyIndexApi {
    //判断是否为管理员
    static isAdmin(params) {
        return request({
            url: "/auth/isAdmin",
            method: "get",
            params: params,
        });
    }
    // 获取首页数据
    static homepage(params) {
        return request({
            url: "/manager/homepage",
            method: "get",
            params: params,
        });
    }
    // 品牌列表
    static brandList(params) {
        return request({
            url: "/manager/brandList",
            method: "get",
            params: params,
        });
    }
    // 品牌详情
    static brandDetail(params) {
        return request({
            url: "/manager/brandDetail",
            method: "get",
            params: params,
        });
    }
    // 新增品牌
    static addBrand(params) {
        return request({
            url: "/manager/addBrand",
            method: "post",
            data: params,
        });
    }
    // 编辑品牌
    static editBrand(params) {
        return request({
            url: "/manager/editBrand",
            method: "post",
            data: params,
        });
    }
       // 搜索员工
       static searchEm(params) {
        return request({
            url: "/manager/searchEmployee",
            method: "get",
            params: params,
        });
    }
    // 文件上传
    static uploadFile(params) {
        return request({
            url: "/file/upload",
            method: "post",
            data: params,
        });
    }
}