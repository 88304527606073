<template>
  <!-- <div class="banxin"> -->
  <!-- 发布的商机 -->
  <div>
    <div
      class="Cmiddle"
      v-for="(item, index) in list"
      :key="index"
      @click="skipMyChanceDetail(item.id, item.audit_status, item.status)"
    >
      <div class="content">
        <div class="ctop">
          <h1>{{ item.name }}</h1>
          <p>
            {{ item.generalize }}
          </p>
        </div>
        <div class="cCenter">
          <div class="box">
            <div class="m17">
              <span>初步预算:</span>
              <i>{{ item.budget_range_start }} - {{ item.budget_range_end }}</i>
            </div>
            <div class="m17">
              <span>需求时间:</span>
              <span
                >{{ item.start_time.slice(0, 10) }} -
                {{ item.end_time.slice(0, 10) }}</span
              >
            </div>
            <div class="m17">
              <span>区域要求:</span> <span>{{ item.area }}</span>
            </div>
            <div class="m17">
              <em
                >创建人：&nbsp;<ww-open-data
                  type="userName"
                  :openid="item.corpEmployee.name"
                />
                &nbsp;</em
              >
            </div>
          </div>
        </div>
        <div class="bottom" v-if="item.audit_status == 2 && item.status == 1">
          <div class="bl">
            <span>已抢：{{ item.grab_num }}人</span>
          </div>
          <button style="margin-top: -4px">
            下架商机
          </button>
        </div>
        <div
          class="bottom"
          v-else-if="item.audit_status == 2 && item.status == 0"
        >
          <div class="bl"></div>
          <button style="margin-top: -4px">已下架</button>
        </div>
        <div
          class="bottom"
          style="justify-content: flex-end"
          v-else-if="item.audit_status == 1"
        >
          <button style="margin-top: -4px">待审核</button>
        </div>
        <div class="bottom" v-else>
          <div class="bl"><span>审核未通过</span></div>
          <button style="margin-top: -4px">重新编辑</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { initAgentConfig } from "../utils/wxCodeAuth";

export default {
  props: ["list"],
  data() {
    return {};
  },
  created() {
    initAgentConfig();
  },
  methods: {
    skipMyChanceDetail(id, audit_status, status) {
      console.log("id audit_status", audit_status);
      if (audit_status == 2 && status == 1) {
        this.$router.push({ path: "/manager/ChanceDetail", query: { id } });
      } else {
        this.$router.push({ path: "/qyChance/edit", query: { id } });
      }
    },
        // 下架商机
      // offChance(id) {
      //   qyChanceApi.closeChance({id}).then(res=>{
      //   if(res.code==0){
      //     this.$toast(res.sMsg)
      //     this.$router.push({path:'/myMarket/index'})
      //   }
      //   else{
      //     this.$toast(res.sMsg)
      //   }     
      // })
      
  //   },
  },
};
</script>
<style lang="less" scoped>
// margin-top: 10px;
.Cmiddle {
  margin-top: 10px;
  min-height: 210px;
  background: #f9f9f9;
  border-radius: 5px;
  overflow: hidden;
  .content {
    margin: 9px auto;
    width: 90%;
    .ctop {
      width: 100%;
      // min-height: 70px;
      h1 {
        // width: 315px;
        height: 21px;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #4a4a4a;
        line-height: 21px;
      }
      p {
        // width: 334px;
        width: 100%;
        min-height: 20px;
        margin: 0;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 19px;
      }
    }
    .cCenter {
      // width: 323px;
      width: 100%;
      height: 107px;
      background: #ffffff;
      border-radius: 5px;
      .box {
        // width: 313px;
        width: 96%;
        margin: 8px auto;
        .m17 {
          min-height: 20px;
          line-height: 18px;

          img {
            width: 16px;
            height: 16px;
          }
        }
        span {
          height: 19px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 19px;
        }
        i {
          height: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #e84a10;
          line-height: 20px;
        }
        em {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }
    }
  }
  .bottom {
    display: flex;
    height: 28px;
    justify-content: space-between;
    align-items: center;
    line-height: 28px;
    margin-top: 8px;
    .bl {
      font-size: 13px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #e84a10;
      line-height: 20px;
    }
    button {
      border: none;
      font-size: 14px;
      color: #fff;
      width: 86px;
      height: 24px;
      line-height: 20px;
      background: #f5a623;
      border-radius: 12px;
    }
  }
}
</style>