import { getJssdkConfig } from "@/api/wxconfig";
import { Toast } from "vant";
import { getCookie, getQuery } from "@/utils/index";
import store from "@/store";

const wx = window.wx;
// 获取微信注入参数
async function getConfigParam(url) {
  try {
    const params = {
      url,
    };
    const { data } = await getJssdkConfig(params);
    return data;
  } catch (e) {
    console.log(e);
  }
}
const jsApiList = [
  "getCurExternalContact",
  "sendChatMessage",
  "getContext",
  "openUserProfile",
  "getCurExternalChat",
  "openExistedChatWithMsg",
  "navigateToAddCustomer",
  "openEnterpriseChat",
  "setClipboardData",
  "onMenuShareWechat",
  "selectExternalContact", 
  "scanQRCode"
];
// wx.config
export function wxConfig(uriPath) {
  return new Promise((resolve, reject) => {
    getConfigParam(uriPath)
      .then((data) => {
        const { url,agentid,openTagList,appId, timestamp, nonceStr, signature } = data;
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: appId, // 必填，企业微信的corpID
          url:url,
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          openTagList:openTagList,
          agentid:agentid,
          jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        wx.ready(async function () {
          resolve();
        });
        wx.error(function (res) {
          Toast({ position: "top", message: "wx.config fail" });
          reject(res);
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}
// 分享
export async function shareMyData(data, retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.onMenuShareWechat({
      title: data.title, // 分享标题
      desc: "", // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await forward(data, true));
        } else {
          reject();
        }
      },
    });
  });
}
// wx.agentConfig
export function agentConfig(uriPath) {
  return new Promise((resolve, reject) => {
    getConfigParam(uriPath)
      .then((data) => {
        const { url,corpid,openTagList,agentid, timestamp, nonceStr, signature } = data;
        wx.agentConfig({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          openTagList:openTagList,
          corpid:corpid,
          url:url,
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList,
          success: function (res) {
            // Toast({ position: 'top', message: '注册成功' })
            resolve();
          },
          fail: function (res) {
            Toast({ position: "top", message: "wx.agentConfig fail" });
            reject(res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function initAgentConfig() {

  // 从企业微信3.0.24及以后版本（可通过企业微信UA判断版本号），无须先调用wx.config，可直接wx.agentConfig.
  // await wxConfig(fullPath)
  const url = window.location.href.split("#")[0];
  await agentConfig(encodeURIComponent(url));
  store.commit("SET_INIT_AGENT_CONFIG", true);
}

export async function getContext(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("getContext", {}, async function (res) {
      if (res.err_msg === "getContext:ok") {
        const entry = res.entry; // 返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools
        resolve(entry);
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await getContext(true));
        } else {
          // 错误处理
          reject(res.err_msg);
        }
      }
    });
  });
}
//扫一扫
export async function scanQRCode(skip) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码）
      success: function (res) {
        console.log(res)
        var result = res.resultStr;//当needResult为1时返回处理结果
        skip(result)
        console.log('result', result)
        // resolve();             
      },
      cancel: async () => {
        
      },
    });
  });
}
// 获取当前对话客户微信userId
export async function getCurExternalContact(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("getCurExternalContact", {}, async function (res) {
      if (res.err_msg === "getCurExternalContact:ok") {
        const userId = res.userId; // 返回当前外部联系人userId
        // commit('SET_WX_USER_ID', userId)
        resolve(userId);
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await getCurExternalContact(true));
        } else {
          // 错误处理
          reject(res.err_msg);
        }
      }
    });
  });
}

export async function sendChatMessage(type, content, retry) {
  // 1 文本 2 图片 3 图文 5 视频 7文件
  if (type == 6) {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      Toast.fail("Mac端暂不支持发送小程序");
      return;
    }
  }
  let param;
  switch (type) {
    case 1:
      param = {
        msgtype: "text",
        text: {
          content,
        },
      };
      break;
    case 2:
      param = {
        msgtype: "image",
        image: {
          mediaid: content.content,
        },
      };
      break;
    case 3:
      param = {
        msgtype: "news",
        news: {
          link: content.content.imageLink,
          title: content.content.title,
          desc: content.content.title,
          imgUrl: content.content.imageFullPath,
        },
      };
      break;
    case 8:
      param = {
        msgtype: "news",
        news: {
          link: content.content.imageLink,
          title: content.content.title,
          desc: content.content.title,
          imgUrl: content.content.imageFullPath,
        },
      };
      break;
    case 5:
      param = {
        msgtype: "video",
        video: {
          mediaid: content.content,
        },
      };
      break;
    case 6:
      param = {
        msgtype: "miniprogram",
        miniprogram: {
          appid: content.appid,
          title: content.title,
          imgUrl: content.imgUrl,
          page: content.page,
        },
      };
      break;
    case 7:
      param = {
        msgtype: "file",
        file: {
          mediaid: content.content,
        },
      };
      break;
  }
  param.enterChat = true;

  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("sendChatMessage", param, async function (res) {
      if (res.err_msg === "sendChatMessage:ok") {
        // 发送成功
        resolve();
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await sendChatMessage(type, content, true));
        } else {
          Toast({ position: "top", message: res.err_msg });
          reject(res.err_msg);
        }
      }
    });
  });
}
// getCurExternalChat
export async function openUserProfile(type, userid, retry) {
  const params = {
    type,
    userid,
  };
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("openUserProfile", params, async function (res) {
      if (res.err_msg === "openUserProfile:ok") {
        // const userId = res.userId // 返回当前外部联系人userId
        // resolve(userId)
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          await openUserProfile(type, userid, true);
        } else {
          Toast({ position: "top", message: res.err_msg });
          // 错误处理
          reject(res.err_msg);
        }
      }
    });
  });
}
// 获取当前客户群的群ID
export async function getCurExternalChat(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("getCurExternalChat", {}, async function (res) {
      if (res.err_msg === "getCurExternalChat:ok") {
        const chatId = res.chatId; // 返回当前外部联系人userId
        resolve(chatId);
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await getCurExternalChat(true));
        }
      }
    });
  });
}
// 打开当前群聊
export async function openExistedChatWithMsg(chatId, retry) {
  await initAgentConfig();
  const params = {
    chatId,
  };
  return new Promise((resolve, reject) => {
    wx.invoke("openExistedChatWithMsg", params, async function (res) {
      if (res.err_msg === "openExistedChatWithMsg:ok") {
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await openExistedChatWithMsg(chatId, true));
        } else {
          Toast({ position: "top", message: res.errmsg });
          // 错误处理
          reject(res.errmsg);
        }
      }
    });
  });
}
// 建群
export async function openEnterpriseChat(
  userIds,
  externalUserIds,
  groupName,
  retry
) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.openEnterpriseChat({
      userIds,
      externalUserIds,
      groupName,
      success: function (res) { },
      fail: async function (res) {
        if (res.errMsg.indexOf("function not exist") > -1) {
          alert("版本过低请升级");
        }

        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(
            await openEnterpriseChat(userIds, externalUserIds, groupName, true)
          );
        }
      },
    });
  });
}
// 剪贴板
export async function setClipboardData(data) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.setClipboardData({
      data,
      success: function (res) { },
      fail: async function (res) { },
    });
  });
}
// 进入添加客户界面  只支持移动端
export async function navigateToAddCustomer(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("navigateToAddCustomer", {}, function (res) {
      if (res.err_msg === "navigateToAddCustomer:ok") {
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(navigateToAddCustomer(true));
        } else {
          Toast({ position: "top", message: res.errmsg });
          // 错误处理
          reject(res.errmsg);
        }
      }
    });
  });
}
// 转发
export async function forward(data, retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.onMenuShareAppMessage({
      title: data.title, // 分享标题
      desc: "", // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: "", // 分享图标
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await forward(data, true));
        } else {
          reject();
        }
      },
    });
  });
}
export async function shareToExternalMoments(text, content) {
  await initAgentConfig();
  var param = {
    text: {
      content: text, // 文本内容
    },
    attachments: [],
  };
  switch (content.type) {
    case 2:
      param.attachments.push({
        msgtype: "image", // 消息类型，必填
        image: {
          mediaid: content.content, // 图片的素材id
        },
      });
      break;
    case 3:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.content.title, // H5消息标题
          imgUrl: content.content.imageFullPath, // H5消息封面图片URL
          url: content.content.imageLink, // H5消息页面url 必填
        },
      });
      break;
    case 8:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.content.title, // H5消息标题
          imgUrl: content.content.imageFullPath, // H5消息封面图片URL
          url: content.content.imageLink, // H5消息页面url 必填
        },
      });
      break;
    case 5:
      param.attachments.push({
        msgtype: "video", // 消息类型，必填
        video: {
          mediaid: content.content, // 视频的素材id
        },
      });
      break;
  }
  return new Promise((resolve, reject) => {
    wx.invoke("shareToExternalMoments", param, async function () {
      if (res.err_msg == "shareToExternalMoments:ok") {
        resolve();
      }
    });
  });
}
